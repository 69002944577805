<template>
  <div class="garnish">
    <Categories :show-garnish="true"/>
    <Ingredients :show-garnish="true" file-type="png">
      <router-link to="/tavla/avsluta">
        <Button>Välj topping & gå vidare</Button>
      </router-link>
    </Ingredients>
    <Modal :show="showModal">
      <h2>Toppa med din favorit</h2>
      <p class="-body-2">Välj vilken produkt från Paulúns du vill toppa din smoothie med.</p>
      <Button :click="closeModal">Välj toppning</Button>
    </Modal>
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Categories, Ingredients, Button, Modal } from '@/components'

export default {
  components: {
    Categories,
    Ingredients,
    Button,
    Modal,
  },
  computed: {
    ...mapGetters({
      garnish: 'recipe/garnish',
      ingredients: 'recipe/ingredients',
    }),
  },
  data: () => ({
    showModal: false,
  }),
  mounted() {
    if (this.ingredients.length === 0) {
      this.showIngrediensModal = true
      this.$router.push({ name: 'noIngredientsAdded' })
    } else {
      this.showModal = this.garnish.length === 0
    }
  },
  methods: {
    closeModal() {
      this.showModal = false
    },
  },
}
</script>

<style lang="scss" scoped>

.garnish {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* Bug fix for iOS which apparently still can't handle flexbox */
@supports (-webkit-touch-callout: none) {
  .garnish  {
    display: block;
    height: auto;
  }
}


/deep/ .modal {
  text-align: center;

  h2 {
    @include rem(font-size, 26px);
    @include rem(line-height, 34px);
    @include rem(margin-bottom, 10px);
    text-align: center;
    color: $green;
  }

  button {
    width: 100%;
  }

  p,
  a {
    display: inline-block;
    @include rem(margin-bottom, $gutter / 2);
  }
}

/deep/ .ingredients {
  @include rem(padding-top, $gutter);
  flex: 1 1 auto;
  margin-top: 0;
  background-color: $cream-white;

  .bottom {
    @include rem(margin-top, 60px);
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}

@media #{$desktop-sm} {
  /deep/ .ingredients {
    .bottom {
      @include rem(margin, 0 0 20px);
    }
  }
}

</style>